import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useSignInCheck } from '../utils/useSignInCheck'

const ProtectedRoute = () => {
    const isAuthenticated = useSignInCheck()

    return isAuthenticated ? <Outlet /> : <Navigate to="/login" />
}

export default ProtectedRoute
