import { FETCH_CATEGORIES } from '../actions/categories'

const initialState = {
  allCategories: [],
}

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES:
      return {
        allCategories: action.categories,
      }
    default:
      return state
  }
}

export default categoriesReducer
