import { Helmet } from 'react-helmet-async'
import { structuredOrganization, structuredProduct } from './jsonLd'
import * as DOMPurify from 'dompurify'

// ref: https://opengraphprotocol.org/
// ref: https://css-tricks.com/essential-meta-tags-social-media/
// test: https://metatags.io/
// test: https://metapreview.app/
// test: https://www.linkedin.com/post-inspector/inspect/

const defaultTitle = "Powershopz"

const defaultDescription = 'Powershopz™ is a place for anyone to buy and sell all kinds of adult products. You can buy and sell almost anything here! Powershopz contains materials of an adult nature, and is intended only for individuals 18 years of age and older.'

const defaultImage = 'https://powershopz.com/category/everything.jpg'

const defaultKeywords = 'powershopz, powershopz.com, power shopz, powershops, power shops, shopping, adult shopping, adult shopping mall, alternative, adult, kinky, cryptocurrency, credit card, zelle, cash app, altcoin, shop, videos, clips, photos, clothing, whips, other, floggers, toys, adult toys, shoes, rope, pictures, medical, leather, latex, lace, lingerie, knives, jewelry, accessories, furniture, electrical, electric play, electro, medical play, dvd, dvds, books, magazines, art, powershotz, restraints, fucking machines, vintage, smoking, crime scene, morgue, bongs, international, mandarin, 普通话, cn, Deutsch, de, Español, es, Français, fr, Italiano, it, 日本語, ja, Русский, ru, aave, algorand, algo, basic-attention-token, bat,  bitcoin, btc, bitcoin-cash, bch, cardano, ada, chainlink, link, cosmos, atom, dai, dash, dogecoin, doge, ethereum, eth, kyber-network, knc, litecoin, ltc, maker, mkr, monero, xmr, nano, neo, omisego, omg, polkadot, dot, shiba inu, shib, status, snt, stellar, xlm, tether, usdt, tezos, xtz, tron, trx, usd coin, usdc, vechain, vet, xrp, zcash, zec'

const defaultLogo = "https://powershopz.com/favicon.png"

const MetaDecorator = ({
  title = defaultTitle,
  keywords = defaultKeywords,
  image = defaultImage,
  description = defaultDescription,
  storeName = 'Powershopz',
  url = window.location.href,
  price = '9.99',
  type = 'organization',
  imageAlt = 'Powershopz',
  siteName = 'Powershopz',
  logo = defaultLogo,
  noscript = 'Welcome to Powershopz. Please enable javascript to shop for just about anything!',
}) => {

  const link = url ? url : window.location.href

  const structuredData = type === 'product'
    ? structuredProduct(
      title,
      image,
      DOMPurify.sanitize(description),
      link,
      price,
      storeName,
      logo
    )
    : structuredOrganization

  // const googleTag = () => {
  //   window.dataLayer = window.dataLayer || []
  //   const gtag = () => {
  //     dataLayer.push(arguments)
  //   }
  //   gtag('js', new Date())
  //   gtag('config', 'G-1S0KPEXWVQ')
  // }

  // place right above closing helmet tag
  // <!-- Google tag (gtag.js) -->
  //    <script
  //     async
  //     src="https://www.googletagmanager.com/gtag/js?id=G-1S0KPEXWVQ"
  //   ></script>
  //    <script>{googleTag()}</script>


  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      <link
        rel="icon"
        type="image/x-icon"
        href="https://powershopz.com/favicon.ico"
      />
      <noscript>{noscript}</noscript>
      <meta name="keywords" content={keywords} />
      <meta name="author" content="Powershotz.com" />

      {/* <!--  Essential META Tags --> */}
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={link} />
      <meta name="twitter:card" content="summary_large_image" />

      {/* <!--  Non-Essential, But Recommended --> */}
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={siteName} />
      <meta name="twitter:image:alt" content={imageAlt} />

      {/* Just added 6/24/23 */}
      <meta name="twitter:site" content="@powershopz" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <script type="application/ld+json">{structuredData}</script>

      {/* Google tag here */}

    </Helmet>
  )
}

export default MetaDecorator
