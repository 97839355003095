import { commission, hostedCommission } from './getData'
import { getCreditCardFee } from '../../screens/vendorScreens/OrdersScreenComponents/calculateCCFee'

export const getVendorAmount = (order, stores) => {

    let fee = 0
    const orderAmount = parseFloat(order.amount)
    const store = stores
        ? stores.find(s => s.uid === order.uid)
        : []

    if (!store) return { vendorAmount: order.amount, fee: 0 }

    const myStore = (store && store.storeName === 'Powershotz') ||
        store.storeName === 'Jouets' ||
        store.storeName === 'Edge'
        ? true
        : false

    const storeFee = store.fee
        ? parseFloat(store.fee)
        : hostedCommission

    if (myStore) {
        // my products
        if (
            // gumroad
            order &&
            order.customerInfo &&
            order.customerInfo.coin &&
            order.customerInfo.coin.value &&
            order.customerInfo.coin.value === 'gumroad'
        ) {
            // const v = orderAmount - (orderAmount * 0.13)
            // const f = orderAmount * 0.13
            const v = orderAmount
            const f = 0
            return { vendorAmount: v, fee: f }
        } else {
            return { vendorAmount: orderAmount, fee: 0 }
        }
    } else {
        // Vendor products
        let sum = 0
        let addAmounts = 0

        order.items.forEach((item) => {
            sum = parseInt(item.quantityOrdered) * parseFloat(item.price)

            if (item.category === 'videos' || item.category === 'photos') {
                addAmounts += sum * (1 - storeFee)
                fee += sum * storeFee
            } else {
                addAmounts += sum * (1 - commission)
                fee += sum * commission
            }
        })

        if (
            // gumroad or coinz
            order &&
            order.customerInfo &&
            order.customerInfo.coin &&
            order.customerInfo.coin.value &&
            (order.customerInfo.coin.value === 'gumroad' ||
                order.customerInfo.coin.value === 'coinz'
            )
        ) {
            const v = addAmounts - (orderAmount * getCreditCardFee())
            const f = fee + (orderAmount * getCreditCardFee())
            return { vendorAmount: v, fee: f }
        } else {
            return { vendorAmount: addAmounts, fee }
        }
    }
}
