import dayjs from 'dayjs'
import { creditCardFee } from '../../../components/adminComponents/getData'

export const calculateCCFee = (orderDate) => {
    const cutoffDate = dayjs("2024-10-09")

    const parsedDate = dayjs(orderDate, "dddd, MMMM D, YYYY")

    const feeRate = parsedDate.isBefore(cutoffDate)
        ? 0.065
        : creditCardFee

    return feeRate
}

export const getCreditCardFee = () => {
    const cutoffDate = dayjs("2024-10-09")
    const today = dayjs()

    const feeRate = today.isBefore(cutoffDate)
        ? 0.065
        : creditCardFee

    return feeRate
}
