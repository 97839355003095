import { combineReducers } from 'redux'

import categoriesReducer from './categories'
import productsReducer from './products'
import storesReducer from './stores'
import cartReducer from './cart'
import ordersReducer from './orders'
import coinsReducer from './coins'
import promoReducer from './promo'
import requestsReducer from './requests'
import castingCallsReducer from './castingCalls'
import helpVideosReducer from './helpVideos'

const rootReducer = combineReducers({
  categories: categoriesReducer,
  products: productsReducer,
  stores: storesReducer,
  cart: cartReducer,
  orders: ordersReducer,
  coins: coinsReducer,
  promo: promoReducer,
  requests: requestsReducer,
  castingCalls: castingCallsReducer,
  helpVideos: helpVideosReducer,
})

export default rootReducer
