// ScrollToTop.js
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = ({ paths }) => {
    const location = useLocation()
    const key = location.key
    const split = location.pathname.split('/')
    const name = split[1]

    useEffect(() => {
        if (paths.includes(name)) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }, [location, paths, name, key])

    return null
}

export default ScrollToTop
