import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import i18n from '../../i18n'
import Colors from '../../constants/Colors'
import TermsModal from '../../Modals/TermsModal'
import { isMobile } from 'react-device-detect'

const time = 1500
const extendedTime = 2500

export const ToastComp = () => {
    return (
        <ToastContainer
            position="top-right"
            autoClose={time}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            closeButton={false}
        />
    )
}

export const handleToast = () =>
    toast.success(i18n.t('main.copied_to_clipboard'), {
        position: 'top-right',
        autoClose: time,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })

export const handleAddToCart = (title) =>
    toast(
        `✅ ${i18n.t('cart.added_to_cart')}: ${title}`
        , {
            position: 'top-right',
            autoClose: time,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })

export const handleRemoveFromToCart = (title) =>
    toast.info(
        i18n.language === 'en'
            ? `${title} removed from cart`
            : i18n.t('cart.removed_from_cart')
        , {
            position: 'top-right',
            autoClose: time,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })

export const handleError = (message) =>
    toast.error(message, {
        position: 'top-right',
        autoClose: time,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })

export const handleBanned = (message) =>
    toast(({ closeToast }) => (
        <div style={styles.bannedDiv}>
            <h3 style={styles.bannedMessage}>{message}</h3>
            <button
                style={styles.bannedButton}
                onClick={closeToast}>
                {i18n.t('modals.close')}
            </button>
        </div>
    ), {
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        position: "top-center",
        style: {
            backgroundColor: '#ddd',
            color: 'black',
            boxShadow: '1px 2px 8px rgba(0,0,0,0.8)',
            width: isMobile ? '100%' : '120%'
        }
    })

export const handleGenToast = (message) =>
    toast.success(message, {
        position: 'top-right',
        autoClose: time,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })

export const handleGenInfo = (message) =>
    toast.info(message, {
        position: 'top-right',
        autoClose: time,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })

export const handleThankYou = () =>
    toast(`🥰 ${i18n.t('thanks.thank_you')}`, {
        position: "top-right",
        autoClose: extendedTime,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })


export const handleGuarantee = (message) => {
    return toast(message, {
        position: "top-right",
        autoClose: extendedTime,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}

export const handlePromise = (func) => {
    toast.promise(
        func,
        {
            pending: 'Promise is pending',
            success: 'Promise resolved 👌',
            error: 'Promise rejected 🤯'
        }
    )
}

export const handleGenInfoNoClose = (message) => {
    toast(
        ({ closeToast }) => (
            <div style={{ ...styles.flexStyleCol, padding: 10 }}>
                <p style={{ fontSize: '1rem' }}>{message}</p>

                <button
                    style={{ marginTop: 15 }}
                    onClick={() => closeToast()}
                >
                    OK
                </button>
            </div>
        ),
        {
            autoClose: false,
            position: "top-center",
            style: {
                backgroundColor: '#ddd',
                color: 'black',
                boxShadow: '1px 2px 8px rgba(0,0,0,0.8)',
                border: '2px solid black'
            }
        }
    )
}

export const handleConfirmDeleteProduct = ({
    closeFunction,
    title
}) => {
    toast(
        ({ closeToast }) => (
            <div style={styles.deleteProductDiv}>
                <h3 style={styles.sureTitle}>{i18n.t('toast.really_sure')}</h3>
                <p>{i18n.t('toast.are_you_sure')} <i>{title}</i></p>
                <p>{i18n.t('toast.still_liable')}</p>

                <div style={styles.buttonsDiv}>
                    <button
                        style={styles.buttons}
                        onClick={() => {
                            closeFunction()
                            handleGenInfo(i18n.t('toast.deleting_product'))
                            closeToast()
                        }}
                    >
                        {i18n.t('admin.yes')}
                    </button>
                    <button
                        style={styles.buttons}
                        onClick={() => {
                            handleGenInfo(i18n.t('toast.cancel_it'))
                            closeToast()
                        }}
                    >
                        {i18n.t('admin.no')}
                    </button>
                </div>
            </div>
        ),
        {
            autoClose: false,
            position: "top-center",
            style: {
                backgroundColor: 'red',
                color: 'black',
                boxShadow: '1px 2px 8px rgba(0,0,0,0.8)'
            }
        }
    )
}

export const handleConfirmSaveProduct = ({ title, uid }) => {

    toast(
        ({ closeToast }) => (
            <div style={styles.deleteProductDiv}>
                <h3>{i18n.t('admin.suc_ces')}</h3>
                <p>{i18n.t('toast.you_saved')} <i style={{ fontSize: '1.175rem', fontFamily: 'open-sans-bold' }}>{title}</i>.</p>
                <div style={styles.buttonsDiv}>
                    <button
                        style={{ ...styles.buttons, padding: 8 }} onClick={() => {
                            if (window.location.pathname === '/edit_product') {
                                window.history.pushState(null, '', `/edit_product/${uid}-${title}`)
                            }
                            closeToast()
                        }}
                    >
                        {i18n.t('toast.continue_editing')}
                    </button>

                    <button
                        style={{ ...styles.buttons, padding: 8 }}
                        onClick={() => {
                            window.location.href = window.location.origin + "/your_products"
                            closeToast()
                        }}
                    >
                        {i18n.t('toast.finish_editing')}
                    </button>
                </div>
            </div>
        ),
        {
            autoClose: false,
            position: "top-center",
            style: {
                backgroundColor: 'lime',
                color: 'black',
                boxShadow: '1px 2px 8px rgba(0,0,0,0.8)'
            }
        }
    )
}

export const handleShopInformation = ({
    storeName,
    storeDescription
}) => {

    const isPowershotz = storeName === 'Powershotz' ? true : false

    const PowershotzLink = () => {
        return (
            <p style={styles.powershotz}>
                View 1000s of free previews and purchase full-length videos, clips, and
                photo sets at{' '}
                <span
                    style={styles.link}
                    onClick={() => window.open('https://powershotz.com')}
                >
                    Powershotz.com
                </span>
            </p>
        )
    }


    toast(
        ({ closeToast }) => (
            <div
                style={{ ...styles.deleteProductDiv, paddingTop: 20 }}
                onClick={() => closeToast()}
            >
                <div style={styles.scroll}>
                    <p style={styles.storeName}>{storeName}</p>

                    <p style={styles.modalText}>{storeDescription}</p>
                    {isPowershotz && <PowershotzLink />}
                </div>

                <div style={styles.flexStyle}>
                    <button
                        style={{}}
                        onClick={() => {
                            closeToast()
                        }}
                    >
                        Close
                    </button>
                </div>
            </div>
        ),
        {
            autoClose: false,
            position: "top-center",
            style: {
                backgroundColor: Colors.accentColor,
                color: 'black',
                boxShadow: '1px 2px 8px rgba(0,0,0,0.8)',
                overflow: 'auto',
            }
        }
    )
}

export const handleTermsModal = () => {
    toast(
        ({ closeToast }) => (
            <div style={styles.flexStyleCol}>
                <TermsModal />
                <button
                    style={{
                        ...styles.buttons,
                        paddingLeft: 10,
                        paddingRight: 10,
                        transform: 'scale(0.75)'
                    }}
                    onClick={() => {
                        closeToast()
                    }}
                >
                    {i18n.t('cart.i_agree')}
                </button>
            </div>
        ),
        {
            autoClose: false,
            position: "top-center",
            style: {
                backgroundColor: '#ddd',
                color: 'black',
                boxShadow: '1px 2px 8px rgba(0,0,0,0.8)',
                width: isMobile ? '100%' : '120%'
            }
        }
    )
}

export const handleGenConfirm = (message) => {
    return new Promise((resolve) => {
        toast(
            ({ closeToast }) => (
                <div style={styles.deleteProductDiv}>
                    <h3 style={styles.genMessage}>{message}</h3>
                    <div style={styles.buttonsDiv}>
                        <button
                            style={{ ...styles.buttons, padding: 8 }} onClick={() => {
                                closeToast()
                                resolve(true)
                            }}
                        >
                            {i18n.t('admin.yes')}
                        </button>

                        <button
                            style={{ ...styles.buttons, padding: 8 }}
                            onClick={() => {
                                closeToast()
                                resolve(false)
                            }}
                        >
                            {i18n.t('admin.no')}
                        </button>
                    </div>
                </div>
            ),
            {
                autoClose: false,
                position: "top-center",
                style: {
                    backgroundColor: '#ddd',
                    color: 'black',
                    boxShadow: '1px 2px 8px rgba(0,0,0,0.8)',
                    width: isMobile ? '100%' : '120%',
                    textAlign: 'center',
                }
            }
        )
    })
}

export const handleConfirmOk = (message) => {
    return new Promise((resolve) => {
        // Unique ID for the toast
        const toastId = 'unique-toast-id'

        // Check if the toast with this ID already exists
        if (!toast.isActive(toastId)) {
            toast(
                ({ closeToast }) => (
                    <div style={{
                        ...styles.deleteProductDiv,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                    >
                        <h3 style={styles.genMessage}>{message}</h3>
                        <button
                            style={{
                                ...styles.buttons,
                                padding: 8,
                                marginTop: 20
                            }}
                            onClick={() => {
                                closeToast()
                                resolve(true)
                            }}
                        >
                            {i18n.t('admin.o_k')}
                        </button>
                    </div>
                ),
                {
                    autoClose: false,
                    position: "top-center",
                    toastId: toastId, // Assign unique ID
                    style: {
                        backgroundColor: '#ddd',
                        color: 'black',
                        boxShadow: '1px 2px 8px rgba(0,0,0,0.8)',
                        width: isMobile ? '100%' : '120%',
                        textAlign: 'center',
                    }
                }
            )
        } else {
            resolve(false) // Resolve if toast is already active
        }
    })
}

const styles = {
    deleteProductDiv: {
        backgroundColor: '#eee',
        padding: 30,
        paddingTop: 5,
        paddingBottom: 20,
    },
    buttonsDiv: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 20,
        marginTop: 30,
    },
    buttons: {
        minWidth: 75,
    },
    powershotz: {
        marginBottom: 15,
        textAlign: 'center',
        fontFamily: 'open-sans',
    },
    link: {
        color: 'blue',
        textDecorationLine: 'underline',
        fontFamily: 'open-sans-bold',
    },
    scroll: {
        padding: 5,
        overflow: 'auto',
    },
    storeName: {
        fontFamily: 'open-sans-bold',
        fontSize: '1.5rem',
        textAlign: 'center',
        margin: 0,
        marginBottom: 10,
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
        fontFamily: 'open-sans',
    },
    flexStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    flexStyleCol: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    bannedDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        border: '5px solid crimson',
        borderRadius: 10,
        padding: 5,
    },
    bannedButton: {
        backgroundColor: 'crimson',
        transform: 'scale(.8)'
    },
    bannedMessage: {
        marginTop: 0,
    },
    genMessage: {
        margin: 0,
        marginTop: 15,
    },
    sureTitle: {
        marginTop: 10,
        marginBottom: 15,
    }
}
