export const useSignInCheck = () => {
    const user = localStorage.getItem('user')

    const currentUser = user &&
        user !== null &&
        user !== 'null' &&
        user !== undefined &&
        user !== 'undefined'
        ? JSON.parse(user)
        : false
    return currentUser
}