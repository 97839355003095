import { useEffect } from 'react'

function CacheBuster() {

    useEffect(() => {
        fetch('https://powershopz.com/meta.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                return response.json()
            })
            .then(meta => {
                const currentVersion = localStorage.getItem('appVersion')
                if (currentVersion !== meta.version) {
                    localStorage.setItem('appVersion', meta.version)
                    window.location.reload(true)
                }
            })
            .catch(error => {
                console.error('Error fetching meta.json:', error)
                // Handle the error gracefully, e.g., show a notification or fallback behavior
            })
    }, [])

    return null
}

export default CacheBuster
