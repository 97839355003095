export const SET_PROMO_FOLDER = 'SET_PROMO_FOLDER'
export const DELETE_PROMO_FOLDER = 'DELETE_PROMO_FOLDER'
export const GET_PROMO_FOLDERS = 'GET_PROMO_FOLDERS'
export const SET_PROMO = 'SET_PROMO'
export const DELETE_PROMO = 'DELETE_PROMO'
export const GET_PROMOS = 'GET_PROMOS'

export const getPromoFolders = () => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/prom/gpf.php'
    const folders = []

    fetch(url, {
      method: 'POST',
    })
      .then(res => res.json())
      .then(data => data.map(i => folders.push(i)))
      .then(() => {
        dispatch({
          type: GET_PROMO_FOLDERS,
          promoFolders: folders,
        })
      })
      .catch((e) => console.log(`Error getting promo folders\n${e.message}`))
  }
}

export const setPromoFolder = (storeName, folder) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/prom/spf.php'

    const data = {
      store: storeName,
      folder: folder,
    }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => {
        dispatch({
          type: SET_PROMO_FOLDER,
          message: 'success!',
        })
      })
      .catch((e) => {
        console.log(`Error posting promo folder\n${e.message}`)
        dispatch({
          type: SET_PROMO_FOLDER,
          message: `Error posting promo folder\n${e.message}`
        })
      })
  }
}

export const updatePromoFolder = (storeName, folder) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/prom/upf.php'

    const data = {
      store: storeName,
      folder: folder,
    }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => {
        dispatch({
          type: SET_PROMO_FOLDER,
          message: 'success!',
        })
      })
      .catch((e) => {
        console.log(`Error posting promo folder\n${e.message}`)
        dispatch({
          type: SET_PROMO_FOLDER,
          message: `Error posting promo folder\n${e.message}`
        })
      })
  }
}

export const deletePromoFolder = (store) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/prom/dpf.php'
    const data = { store: store }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => {
        dispatch({
          type: DELETE_PROMO_FOLDER,
          message: 'success!',
        })
      })
      .catch((e) => {
        console.log(`Error deleting promo folder\n${e.message}`)
        dispatch({
          type: DELETE_PROMO_FOLDER,
          message: `Error deleting promo folder\n${e.message}`
        })
      })
  }
}

export const setPromo = (storeName, description, fileName, promo) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/prom/sp.php'

    const data = {
      store: storeName,
      description: description,
      fileName: fileName,
      promo: promo,
    }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => {
        dispatch({
          type: SET_PROMO,
          message: 'success!',
        })
      })
      .catch((e) => {
        console.log(`Error posting promo\n${e.message}`)
        dispatch({
          type: SET_PROMO,
          message: `Error posting promo\n${e.message}`
        })
      })
  }
}

export const updatePromo = (description, fileName, promo) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/prom/up.php'

    const data = {
      description: description,
      fileName: fileName,
      promo: promo,
    }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => {
        dispatch({
          type: SET_PROMO,
          message: 'success!',
        })
      })
      .catch((e) => {
        console.log(`Error updating promo\n${e.message}`)
        dispatch({
          type: SET_PROMO,
          message: `Error updating promo\n${e.message}`
        })
      })
  }
}

export const deletePromo = (promo) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/prom/dp.php'
    const data = { id: promo.id }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => {
        dispatch({
          type: DELETE_PROMO,
          message: 'success!',
        })
      })
      .catch((e) => {
        console.log(`Error deleting promo\n${e.message}`)
        dispatch({
          type: DELETE_PROMO,
          message: `Error deleting promo\n${e.message}`
        })
      })
  }
}

export const getPromos = () => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/prom/gp.php'
    const promos = []

    fetch(url, {
      method: 'POST',
    })
      .then(res => res.json())
      .then(data => data.map(i => promos.push(i)))
      .then(() => {
        dispatch({
          type: GET_PROMOS,
          promos: promos,
        })
      })
      .catch((e) => console.log(`Error getting promos\n${e.message}`))
  }
}
