export const SET_HELP_VIDEO = 'SET_HELP_VIDEO'
export const GET_HELP_VIDEOS = 'GET_HELP_VIDEOS'
export const DELETE_HELP_VIDEO = 'DELETE_HELP_VIDEO'

export const setHelpVideo = (data) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/hv/shv.php'

    const d = {
      title: data.title,
      category: data.category,
      url: data.url,
      showAtPowershotz: data.showAtPowershotz,
      showAtPowershopz: data.showAtPowershopz,
    }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(d),
    })
      .then(() => {
        dispatch({
          type: SET_HELP_VIDEO,
          message: 'success!',
        })
      })
      .catch((e) => {
        console.log(`Error posting help video\n${e.message}`)
        dispatch({
          type: SET_HELP_VIDEO,
          message: `Error posting help video\n${e.message}`
        })
      })
  }
}

export const getHelpVideos = (data) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/hv/ghv.php'
    const helpVideos = []

    fetch(url, {
      method: 'POST',
    })
      .then(res => res.json())
      .then(data => data.map(i => helpVideos.push(i)))
      .then(() => {
        dispatch({
          type: GET_HELP_VIDEOS,
          data: helpVideos,
        })
      })
      .catch((e) => {
        console.log(`Error getting help videos\n${e.message}`)
        dispatch({
          type: GET_HELP_VIDEOS,
          message: `Error getting help videos\n${e.message}`
        })
      })
  }
}

export const deleteHelpVideo = (id) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/hv/dhv.php'
    const data = { id: id }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => {
        dispatch({
          type: DELETE_HELP_VIDEO,
          message: 'success!',
        })
      })
      .catch((e) => {
        console.log(`Error deleting help video\n${e.message}`)
        dispatch({
          type: DELETE_HELP_VIDEO,
          message: `Error deleting help video\n${e.message}`
        })
      })
  }
}
