import i18n from '../i18n'

const btc = 'https://powershopz.com/altcoin/btc.png'
const aave = 'https://powershopz.com/altcoin/aave.png'
const bat = 'https://powershopz.com/altcoin/bat.png'
const bnb = 'https://powershopz.com/altcoin/bnb.png'
const bch = 'https://powershopz.com/altcoin/bch.png'
const btg = 'https://powershopz.com/altcoin/btg.png'
const bsv = 'https://powershopz.com/altcoin/bsv.png'
const ada = 'https://powershopz.com/altcoin/ada.png'
const link = 'https://powershopz.com/altcoin/link.png'
const atom = 'https://powershopz.com/altcoin/atom.png'
const dai = 'https://powershopz.com/altcoin/dai.png'
const dash = 'https://powershopz.com/altcoin/dash.png'
const doge = 'https://powershopz.com/altcoin/doge.png'
const eth = 'https://powershopz.com/altcoin/eth.png'
const icx = 'https://powershopz.com/altcoin/icx.png'
const knc = 'https://powershopz.com/altcoin/knc.png'
const lsk = 'https://powershopz.com/altcoin/lsk.png'
const ltc = 'https://powershopz.com/altcoin/ltc.png'
const mkr = 'https://powershopz.com/altcoin/mkr.png'
const xmr = 'https://powershopz.com/altcoin/xmr.png'
const nano = 'https://powershopz.com/altcoin/nano.png'
const xem = 'https://powershopz.com/altcoin/xem.png'
const neo = 'https://powershopz.com/altcoin/neo.png'
const omg = 'https://powershopz.com/altcoin/omg.png'
const dot = 'https://powershopz.com/altcoin/dot.png'
const qtum = 'https://powershopz.com/altcoin/qtum.png'
const ren = 'https://powershopz.com/altcoin/ren.png'
const snt = 'https://powershopz.com/altcoin/snt.png'
const xlm = 'https://powershopz.com/altcoin/xlm.png'
const usdt = 'https://powershopz.com/altcoin/usdt.png'
const xtz = 'https://powershopz.com/altcoin/xtz.png'
const trx = 'https://powershopz.com/altcoin/trx.png'
const vet = 'https://powershopz.com/altcoin/vet.png'
const xrp = 'https://powershopz.com/altcoin/xrp.png'
const zec = 'https://powershopz.com/altcoin/zec.png'
const cashapp = 'https://powershopz.com/altcoin/cashApp.png'
// const zelle = 'https://powershopz.com/altcoin/zelle.png'
const invoice = 'https://powershopz.com/altcoin/invoice.png'
const shiba = 'https://powershopz.com/altcoin/shiba.png'
const usdc = 'https://powershopz.com/altcoin/usdc.png'

// exodus address: 'bc1qccj5zxshpg6ck8pffw3pjaq8us47zru26tkc57',

const DROPDOWN_COINS = [
  {
    label: 'Bitcoin (BTC)',
    value: 'BTC',
    name: 'bitcoin',
    address: 'bc1qruc9psupppjm97ukmuzu48j4uj9dm3r7a0uja0',
    image: btc,
  },
  {
    label: 'Aave (AAVE)',
    value: 'AAVE',
    name: 'aave',
    address: '0x3f7bC09733E74c471491c62328045D521CD994da',
    image: aave,
  },
  {
    label: 'Basic-Attention-Token (BAT)',
    value: 'BAT',
    name: 'basic-attention-token',
    address: '0x3f7bC09733E74c471491c62328045D521CD994da',
    image: bat,
  },
  {
    label: 'BNB (BNB)',
    value: 'BNB',
    name: 'binancecoin',
    address: '0x3f7bC09733E74c471491c62328045D521CD994da',
    image: bnb,
  },
  {
    label: 'Bitcoin-Cash (BCH)',
    value: 'BCH',
    name: 'bitcoin-cash',
    address: 'qrxrrqw6x8hz4ukv4ngjfjcmxc2spcllssuq9gt62j',
    image: bch,
  },
  {
    label: 'Bitcoin-Gold (BTG)',
    value: 'BTG',
    name: 'bitcoin-gold',
    address: 'GNc83NEcWDoHY1NWXSfabMvJMxa3cBMARw',
    image: btg,
  },
  {
    label: 'Bitcoin-Cash-SV (BSV)',
    value: 'BSV',
    name: 'bitcoin-cash-sv',
    address: '1LxweJS3CuhqnXR5Wt9C6BwmQtTwyWDM5Y',
    image: bsv,
  },
  {
    label: 'By Mail (USD)',
    value: 'USD',
    name: 'By Mail',
    address: 'DesignTech; PO Box 141; Wildwood, MO 63038 USA',
    image: invoice,
  },
  {
    label: 'Cardano (ADA)',
    value: 'ADA',
    name: 'cardano',
    address:
      'addr1q95funmpvjnpq9xyv3gmgzuvlg92pnyn7p07mz2uaxuefxmgne8kze9xzq2vgez3ks9ce7s25rxf8uzlaky4e6dejjdsun3p7t',
    image: ada,
  },
  {
    label: 'Cash App (USD)',
    value: 'CASHAPP',
    name: 'cash-app',
    address: '$Powershotz',
    image: cashapp,
  },
  {
    label: 'Chainlink (LINK)',
    value: 'LINK',
    name: 'chainlink',
    address: '0x3f7bC09733E74c471491c62328045D521CD994da',
    image: link,
  },
  {
    label: 'Cosmos (ATOM)',
    value: 'ATOM',
    name: 'cosmos',
    address: 'cosmos1m3y5swtrvshp5nf4k0d0qr0msc6q90nlfzspnz',
    image: atom,
  },
  {
    label: 'DAI (DAI)',
    value: 'DAI',
    name: 'dai',
    address: '0x3f7bC09733E74c471491c62328045D521CD994da',
    image: dai,
  },
  {
    label: 'Dash (DASH)',
    value: 'DASH',
    name: 'dash',
    address: 'XyD6jERA13AuMPmXj4SnNVjuUXkZxYi9gm',
    image: dash,
  },
  {
    label: 'Dogecoin (DOGE)',
    value: 'DOGE',
    name: 'dogecoin',
    address: 'DGwcEB2kZxKLwYH7VUKxyyNHStZMcH5b4L',
    image: doge,
  },
  {
    label: 'Ethereum (ETH)',
    value: 'ETH',
    name: 'ethereum',
    address: '0x3f7bC09733E74c471491c62328045D521CD994da',
    image: eth,
  },
  {
    label: 'ICON (ICX)',
    value: 'ICX',
    name: 'icon',
    address: 'hx97544e74ec0678f916eee73ed64e20ca282de3e9',
    image: icx,
  },
  {
    label: 'Kyber-Network (KNC)',
    value: 'KNC',
    name: 'kyber-network',
    address: '0x3f7bC09733E74c471491c62328045D521CD994da',
    image: knc,
  },
  {
    label: 'Lisk (LSK)',
    value: 'LSK',
    name: 'lisk',
    address: 'lsk3euza4jcc6z2syx7z3c4zn7krfn43qd9vevp86',
    image: lsk,
  },
  {
    label: 'Litecoin (LTC)',
    value: 'LTC',
    name: 'litecoin',
    address: 'LNGWBEA8zBRsPZWddYCcwzuA94V7ZUNgdL',
    image: ltc,
  },
  {
    label: 'Maker (MKR)',
    value: 'MKR',
    name: 'maker',
    address: '0x3f7bC09733E74c471491c62328045D521CD994da',
    image: mkr,
  },
  {
    label: 'Monero (XMR)',
    value: 'XMR',
    name: 'monero',
    address:
      '45ecCKa5pPm11mBCrZ6tbS53bpouLFD66QABrQtcxxYmaeYbX6Ui2c4F5WPKD5xTyU71x4rzzvKVfGSgxDKq6N2wCGNGP2G',
    image: xmr,
  },
  {
    label: 'Nano (NANO)',
    value: 'NANO',
    name: 'nano',
    address:
      'nano_3dhmzj3ofhsk9ukwfsemezn4pm68gychort3mcyep6czaxepm6xfbhjiox1f',
    image: nano,
  },
  {
    label: 'NEM (XEM)',
    value: 'XEM',
    name: 'nem',
    address: 'NDZU5EH2NB3WXMM3LZIDFXEYGNYDM3FCOTKRSJLE',
    image: xem,
  },
  {
    label: 'Neo (NEO)',
    value: 'NEO',
    name: 'neo',
    address: 'AXo4SfXwkZNwLmp54hZScpvTNUqYDHLuKV',
    image: neo,
  },
  {
    label: 'Omisego (OMG)',
    value: 'OMG',
    name: 'omisego',
    address: '0x3f7bC09733E74c471491c62328045D521CD994da',
    image: omg,
  },
  {
    label: 'Polkadot (DOT)',
    value: 'DOT',
    name: 'polkadot',
    address: '13SZLBwyZJCiaeN2EVnr25Zb13y9VJcHCtVZdHcSZ8T3Rr79',
    image: dot,
  },
  {
    label: 'Qtum (QTUM)',
    value: 'QTUM',
    name: 'qtum',
    address: 'QicBA1fWmeAmcqne7VbtA6m5TCfkYLhZqq',
    image: qtum,
  },
  {
    label: 'Republic-Protocol (REN)',
    value: 'REN',
    name: 'republic-protocol',
    address: '0x3f7bC09733E74c471491c62328045D521CD994da',
    image: ren,
  },
  {
    label: 'Ripple (XRP)',
    value: 'XRP',
    name: 'ripple',
    address: 'r3rKMMntN9We78ZmPyBso6MUJeRvWRHMxu',
    image: xrp,
  },
  {
    label: 'Shiba Inu (SHIB)',
    value: 'SHIB',
    name: 'shiba-inu',
    address: '0x3f7bC09733E74c471491c62328045D521CD994da',
    image: shiba,
  },
  {
    label: 'Status (SNT)',
    value: 'SNT',
    name: 'status',
    address: '0x3f7bC09733E74c471491c62328045D521CD994da',
    image: snt,
  },
  {
    label: 'Stellar (XLM)',
    value: 'XLM',
    name: 'stellar',
    address: 'GCEFLGC4XPIAJTRD7GMPVNY7HQBR7AQQI2JV66ELOIX5EBYNRPKU7K77',
    image: xlm,
  },
  {
    label: 'Tether (USDT)',
    value: 'USDT',
    name: 'tether',
    address: '0x3f7bC09733E74c471491c62328045D521CD994da',
    image: usdt,
  },
  {
    label: 'Tezos (XTZ)',
    value: 'XTZ',
    name: 'tezos',
    address: 'tz1PD9FUQGsTkbFX9AZbsovzG2bLhLfidiu4',
    image: xtz,
  },
  {
    label: 'Tron (TRX)',
    value: 'TRX',
    name: 'tron',
    address: 'TCjvbvA269aj9q14nfRwvzgx472Yu8daw8',
    image: trx,
  },
  {
    label: 'USD Coin (USDC)',
    value: 'USDC',
    name: 'usd-coin',
    address: '0x3f7bC09733E74c471491c62328045D521CD994da',
    image: usdc,
  },
  {
    label: 'VeChain (VET)',
    value: 'VET',
    name: 'vechain',
    address: '0xb156c973476633e2Eb6634b65df2c2ca26f0ec90',
    image: vet,
  },
  // {
  //   label: 'Wise (USD)',
  //   value: 'WISE',
  //   name: 'wise',
  //   address: 'alexandra@powershotz.com',
  //   image: wise,
  // },
  {
    label: 'Zcash (ZEC)',
    value: 'ZEC',
    name: 'zcash',
    address: 't1aHKAqbFzoBxghvR1eGJE4LiixRQAx9DgK',
    image: zec,
  },
  {
    label: 'Zelle (USD)',
    value: 'ZELLE',
    name: 'zelle',
    address: 'zelle@powershopz.com',
    image: '',
  }
]

export const paymentMethods = () => {
  const payArray = []
  // console.log('paymentMethods ran')

  DROPDOWN_COINS.forEach(coin => {
    if (
      coin.name === 'ripple' ||
      coin.name === 'stellar' ||
      coin.name === 'tron' ||
      coin.name === 'cash-app' ||
      coin.name === 'zelle' ||
      coin.name === 'bitcoin' ||
      coin.name === 'ethereum'
    ) {
      payArray.push(coin)
    }
  })

  const paypal = {
    label: 'PayPal (friends and family ONLY)',
    value: 'PayPal',
    name: 'PayPal',
    address: '',
    image: '',
  }

  payArray.push(paypal)

  const xoom = {
    label: 'Xoom (friends and family ONLY)',
    value: 'Xoom',
    name: 'Xoom',
    address: '',
    image: '',
  }

  payArray.push(xoom)

  payArray.unshift({
    label: i18n.t('main.select'),
    value: 'select',
    name: 'select',
    address: '',
    image: '',
  })

  return payArray
}

export default DROPDOWN_COINS
