import dayjs from 'dayjs'
import axios from 'axios'
import { updateLatestProduct, updateIsPublished } from './stores'
import { getDate } from '../../functions/getDate'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const PREVIEW_PRODUCT = 'PREVIEW_PRODUCT'
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const FETCH_PUBLISHED_PRODUCTS = 'FETCH_PUBLISHED_PRODUCTS'
export const FETCH_PRODUCTS_RANDOM = 'FETCH_PRODUCTS_RANDOM'
export const FETCH_VENDOR_PRODUCTS = 'FETCH_VENDOR_PRODUCTS'
export const TEMP_IMG = 'TEMP_IMG'
export const FIND_PRODUCT = 'FIND_PRODUCT'
export const FIND_PRODUCT_BY_TITLE = 'FIND_PRODUCT_BY_TITLE'
export const SET_PRODUCT = 'SET_PRODUCT'
export const SALE_PRODUCTS = 'SALE_PRODUCTS'
export const NEWEST_PRODUCTS = 'NEWEST_PRODUCTS'
export const FETCH_CATEGORY_PRODUCTS = 'FETCH_CATEGORY_PRODUCTS'

const createArray = (tags) => {
  const arr = []
  if (tags) {
    const split = tags.split(',')
    split.map(i => arr.push(i))
  }
  return arr
}

const createTagArray = (tags) => {
  const arr = []
  const split = tags.split(',')
  split.map(i => !!i && arr.push({ label: i, value: i }))
  return arr
}

export const formatProductData = (product) => {
  let prod = product[0] ? product[0] : product

  if (!prod) return null
  const data = {
    ...prod,
    date: dayjs(prod.date).format('MMMM DD, YYYY'),
    unformattedDate: prod.date,
    price: parseFloat(prod.price),
    salePrice: parseFloat(prod.salePrice),
    tags: prod.tags && createTagArray(prod.tags),
    imageArray: createArray(prod.imageArray),
    canBundle: parseInt(prod.canBundle),
    checkLink: parseInt(prod.checkLink),
    displayOrder: parseInt(prod.displayOrder),
    hosted: parseInt(prod.hosted),
    isFlatRate: parseInt(prod.isFlatRate),
    isPublished: parseInt(prod.isPublished),
    sale: parseInt(prod.sale),
    shippingIncluded: parseInt(prod.shippingIncluded),
    sold: parseInt(prod.numSold ? prod.numSold : prod.sold),
    weight: parseFloat(prod.weight),
    title: prod.title ? prod.title : '',
  }
  return data
}
export const formatProductDataCheckout = (prod) => {
  if (!prod) return null

  const data = {
    ...prod,
    date: dayjs(prod.date).format('MMMM DD, YYYY'),
    unformattedDate: prod.date,
    price: parseFloat(prod.price),
    salePrice: parseFloat(prod.salePrice),
    canBundle: parseInt(prod.canBundle),
    hosted: parseInt(prod.hosted),
    isFlatRate: parseInt(prod.isFlatRate),
    isPublished: parseInt(prod.isPublished),
    quantity: parseInt(prod.quantity),
    sale: parseInt(prod.sale),
    shippingIncluded: parseInt(prod.shippingIncluded),
    weight: parseFloat(prod.weight),
    title: prod.title ? prod.title : '',
  }
  return data
}

export const fetchAllProductsCheckout = () => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/p/fap2.php'
    const products = []

    fetch(url, {
      method: 'POST',
    }).then(res => res.json())
      .then(data => data.map(i => products.push(formatProductDataCheckout(i))))
      .then(() => {
        dispatch({
          type: FETCH_PRODUCTS,
          products: products,
        })
      })
      .catch((e) => console.log(`Error fetching all products\n${e.message}`))
  }
}

export const fetchAllProductsNoLinks = () => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/p/fapnl.php'
    const products = []

    fetch(url, {
      method: 'POST',
    }).then(res => res.json())
      .then(data => data.map(i => products.push(formatProductData(i))))
      .then(() => {
        dispatch({
          type: FETCH_PRODUCTS,
          products: products,
        })
      })
      .catch((e) => console.log(`Error fetching all products\n${e.message}`))
  }
}

export const fetchPublishedProducts = () => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/p/fpp.php'
    const products = []

    fetch(url, {
      method: 'POST',
    }).then(res => res.json())
      .then(data => data.map(i => products.push(formatProductData(i))))
      .then(() => {
        dispatch({
          type: FETCH_PUBLISHED_PRODUCTS,
          products: products,
        })
      })
      .catch((e) => console.log(`Error fetching all products\n${e.message}`))
  }
}

export const fetchCategoryProducts = (category) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/p/fcp.php'
    const data = { category }

    const products = []

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then(res => res.json())
      .then(data => data.map(i => products.push(formatProductData(i))))
      .then(() => {
        dispatch({
          type: FETCH_CATEGORY_PRODUCTS,
          products: products,
        })
      })
      .catch((e) => console.log(`Error fetching category products\n${e.message}`))
  }
}

export const fetchAllProductsRandom = () => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/p/fapr.php'
    const products = []

    fetch(url, {
      method: 'POST',
    }).then(res => res.json())
      .then(data => data.map(i => products.push(formatProductData(i))))
      .then(() => {
        dispatch({
          type: FETCH_PRODUCTS_RANDOM,
          products: products,
        })
      })
      .catch((e) => console.log(`Error fetching all products\n${e.message}`))
  }
}

export const fetchVendorProductsNoLinks = (uid, type, published) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/p/fvpnl.php'
    const data = { uid, type, published }
    const products = []

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(data => data.map(i => products.push(formatProductData(i))))
      .then(() => {
        dispatch({
          type: FETCH_VENDOR_PRODUCTS,
          vendorProducts: products,
        })
      })
      .catch((e) => console.log(`Error loading vendor products\n${e.message}`))
  }
}

export const fetchSaleProducts = () => {
  const url = 'https://powershopz.com/php/p/fsp.php'

  return async (dispatch) => {
    const products = []
    // SQL
    fetch(url, {
      method: 'POST',
    })
      .then(res => res.json())
      .then(data => data.map(i => products.push(formatProductData(i))))
      .then(() => {
        dispatch({
          type: SALE_PRODUCTS,
          saleProducts: products,
        })
      })
      .catch((e) => console.log(`Error loading sale products\n${e.message}`))
  }
}

export const fetchNewestProducts = () => {
  const url = 'https://powershopz.com/php/p/fnp.php'

  return async (dispatch) => {
    const products = []
    // SQL
    fetch(url, {
      method: 'POST',
    })
      .then(res => res.json())
      .then(data => data.map(i => products.push(formatProductData(i))))
      .then(() => {
        dispatch({
          type: NEWEST_PRODUCTS,
          newestProducts: products,
        })
      })
      .catch((e) => console.log(`Error loading newest products\n${e.message}`))
  }
}

export const setPreviewVideoLink = (productId, link) => {
  if (!productId) return

  const url = 'https://powershopz.com/php/p/spvl.php'

  const data = {
    productId: productId,
    link: link,
  }

  return async (dispatch, getState) => {
    // SQL
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .catch((e) => console.log(`Error setting preview video link\n${e.message}`))
  }
}

export const setImageLink = (productId, imgName, url) => {
  const setImgUrl = 'https://powershopz.com/php/p/sil.php'

  const data = {
    productId: productId,
    imgName: imgName,
    url: url,
  }

  return async (dispatch, getState) => {
    // SQL
    fetch(setImgUrl, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .catch((e) => console.log(`Error setting image link\n${e.message}`))
  }
}

export const setFileName = (productId, newFileName) => {
  if (!productId) return

  const url = 'https://powershopz.com/php/p/sfn.php'

  const data = {
    productId: productId,
    newFileName: newFileName,
  }

  return async (dispatch, getState) => {
    // SQL
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .catch((e) => console.log(`Error setting file name\n${e.message}`))
  }
}

export const setDownloadLink = (productId, newDownloadLink) => {
  if (!productId) return

  const url = 'https://powershopz.com/php/p/sdl.php'

  const data = {
    productId: productId,
    newDownloadLink: newDownloadLink,
  }

  return async (dispatch, getState) => {
    // SQL
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .catch((e) => console.log(`Error setting download link\n${e.message}`))
  }
}

export const setDisplayOrder = (productId, index) => {
  if (!productId) return

  const url = 'https://powershopz.com/php/p/sdo.php'

  const data = {
    productId: productId,
    index: index,
  }

  return async (dispatch, getState) => {
    // SQL
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .catch((e) => console.log(`Error setting display order\n${e.message}`))
  }
}

export const setProduct = (newProduct) => {

  return async (dispatch) => {
    // convert t/f to 1/0
    let canBundle = newProduct.canBundle
    let checkLink = newProduct.checkLink
    let hosted = newProduct.hosted
    let isFlatRate = newProduct.isFlatRate
    let isPublished = newProduct.isPublished
    let sale = newProduct.sale
    let shippingIncluded = newProduct.shippingIncluded

    const date = getDate(newProduct.date, newProduct.unformattedDate, newProduct.referenceNumber)

    if (typeof canBundle === 'boolean') {
      canBundle = newProduct.canBundle ? 1 : 0
    }
    if (typeof checkLink === 'boolean') {
      checkLink = newProduct.checkLink ? 1 : 0
    }
    if (typeof hosted === 'boolean') {
      hosted = newProduct.hosted ? 1 : 0
    }
    if (typeof isFlatRate === 'boolean') {
      isFlatRate = newProduct.isFlatRate ? 1 : 0
    }
    if (typeof isPublished === 'boolean') {
      isPublished = newProduct.isPublished ? 1 : 0
    }
    if (typeof sale === 'boolean') {
      sale = newProduct.sale ? 1 : 0
    }
    if (typeof shippingIncluded === 'boolean') {
      shippingIncluded = newProduct.shippingIncluded ? 1 : 0
    }

    const data = {
      ...newProduct,
      date: date,
      canBundle: canBundle,
      checkLink: checkLink,
      hosted: hosted,
      isFlatRate: isFlatRate,
      isPublished: isPublished,
      sale: sale,
      shippingIncluded: shippingIncluded,
    }

    const url = 'https://powershopz.com/php/p/ap.php'

    // this will add or update a product
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => dispatch({ type: SET_PRODUCT, product: data }))
      .catch((e) => console.log(`Error setting product\n${e.message}`))

    // update store's latestProduct date
    if ((!newProduct.date ||
      newProduct.date === '' ||
      newProduct.date === undefined ||
      newProduct.date === 'undefined')) {
      updateLatestProduct(newProduct.uid)
    }
    // update store's isPublished status
    newProduct.isPublished === 1 && updateIsPublished(newProduct.uid, 1)
  }
}

// delete product image from server
export const handleDeleteImage = async (imageUri) => {
  const deleteUrl =
    'https://powershopz.com/productImages/productImagesDelete.php'

  const pop = imageUri.split('/productImages/').pop()
  const data = { file: pop }

  if (data.file !== '') {
    await axios.post(deleteUrl, data).catch((e) => console.log(e.message))
  }
}

// delete preview from server
export const handleDeleteVideo = async (videoUri) => {
  const url = 'https://powershopz.com/previews/previewDelete.php'

  const pop = videoUri.split('/previews/').pop()
  const data = { file: pop }

  if (data.file !== '') {
    await axios.post(url, data).catch((e) => console.log(e.message))
  }
}

// email me info about deleting product download from Sync
const emailMeSyncFileDelete = async (fileName, storeName, email, title) => {
  const url = 'https://powershopz.com/php/em/emmsfd.php'

  const pop = fileName.split('/productImages/').pop()
  const data = {
    path: fileName,
    file: pop,
    storeName: storeName,
    email: email,
    title: title,
  }

  if (fileName !== '') {
    await axios.post(url, data).catch((e) => console.log(e.message))
  }
}

export const deleteProduct = (product) => {
  if (!product) return

  const url = 'https://powershopz.com/php/p/dp.php'

  const data = {
    productId: product.id,
  }

  return async (dispatch, getState) => {
    const productId = product.id

    // delete images from server
    const deleteImg = async (img) => {
      if (img === '') return
      await handleDeleteImage(img)
    }

    // delete video from server
    const deleteVid = async (vid) => {
      if (vid === '') return
      await handleDeleteVideo(vid)
    }

    // delete images and preview video from my server
    await deleteImg(product.image)
    await deleteImg(product.image2)
    await deleteImg(product.image3)
    await deleteImg(product.image4)
    await deleteVid(product.video)

    // alert me to file removal from sync.com
    let isHosted = false
    if (typeof product.hosted === 'boolean' && product.hosted) {
      isHosted = true
    } else if (parseInt(product.hosted) === 1) {
      isHosted = true
    }

    const fileName = product.fileName
    if (isHosted && fileName !== '')
      emailMeSyncFileDelete(
        fileName,
        product.storeName,
        product.email,
        product.title
      )

    // SQL
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => dispatch({ type: DELETE_PRODUCT, productId: productId }))
      .catch((e) => console.log(`Error deleting product\n${e.message}`))
  }
}

export const previewProduct = (previewProduct) => {
  return { type: PREVIEW_PRODUCT, product: previewProduct }
}

export const findProduct = (productId) => {
  if (!productId) return

  const url = 'https://powershopz.com/php/p/fp.php'

  const data = { productId }

  return async (dispatch, getState) => {
    // SQL
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FIND_PRODUCT,
          product: formatProductData(data)
        })
      })
      .catch((e) => console.log(`Error finding product\n${e.message}`))
  }
}

export const findProductByTitle = (title, storeName) => {
  if (!title || !storeName) return

  const url = 'https://powershopz.com/php/p/fpbt.php'

  const data = { title, storeName }

  return async (dispatch, getState) => {
    // SQL
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FIND_PRODUCT_BY_TITLE,
          product: formatProductData(data)
        })
      })
      .catch((e) => console.log(`Error finding product by title\n${e.message}`))
  }
}

export const updateSale = (productId, qty) => {
  if (!productId) return

  const url = 'https://powershopz.com/php/p/us.php'

  const data = { productId, qty }

  return async (dispatch, getState) => {
    // SQL
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .catch((e) => console.log(`Error updating product sales stat\n${e.message}`))
  }
}

export const updateQuantity = (productId, qty) => {
  if (!productId) return

  const url = 'https://powershopz.com/php/p/uq.php'

  const data = { productId, qty }

  return async (dispatch, getState) => {
    // SQL
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .catch((e) => console.log(`Error updating product quantity\n${e.message}`))
  }
}