import {
  ADD_ORDER,
  FETCH_ORDERS,
  UPDATE_ORDER,
  DELETE_ORDER,
  FETCH_VENDOR_ORDERS,
  FETCH_ORDER,
  FETCH_ALL_CUSTOMER_ORDERS,
} from '../actions/orders'

const initialState = {
  orders: [],
  vendorOrders: [],
  numberOfOrders: 0,
  customerOrder: [],
  allCustomerOrders: [],
}

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDER:
      return {
        ...state,
        customerOrder: action.customerOrder,
      }
    case FETCH_ALL_CUSTOMER_ORDERS:
      return {
        ...state,
        allCustomerOrders: action.allCustomerOrders,
      }
    case UPDATE_ORDER:
      const updatedOrder = action.order
      const orderIndex = state.orders.findIndex(
        (order) => order.id === action.order.id
      )
      const updatedOrders = [...state.orders]
      updatedOrders[orderIndex] = updatedOrder
      return {
        ...state,
        orders: updatedOrders,
      }
    case FETCH_ORDERS:
      return {
        ...state,
        orders: action.orders,
        numberOfOrders: action.orders.length,
      }
    case FETCH_VENDOR_ORDERS:
      return {
        ...state,
        vendorOrders: action.vendorOrders,
      }
    case ADD_ORDER:
      return {
        ...state,
        orders: state.orders.concat(action.order),
      }
    case DELETE_ORDER: //local delete
      if (state.orders.length === 1) {
        return initialState
      } else {
        const updatedOrderList = [...state.orders]
        updatedOrderList.filter((order) => order.id !== action.orderId)
        return {
          ...state,
          orders: updatedOrderList,
        }
      }
    default:
      return state
  }
}

export default ordersReducer
