import loadable from '@loadable/component'
import { isMobile } from 'react-device-detect'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import NavbarAnimated from './navigation/NavbarAnimated'
import ProtectedRoute from './navigation/ProtectedRoute'
import './index.css'
import CacheBuster from './CacheBuster'
import ScrollToTop from './utils/ScrollToTop'
import Loader from './components/Loader'
import MetaDecorator from './components/MetaDecorator'
import { ToastComp } from './components/Toast/toastComp'

const YourAdminScreen = loadable(() => import('./screens/YourAdminScreen'), { fallback: <Loader /> })
const PayoutScreen = loadable(() => import('./screens/PayoutScreen'), { fallback: <Loader /> })
const PowerCoinzScreen = loadable(() => import('./screens/PowerCoinzScreen'), { fallback: <Loader /> })
const PowerCoinzAdmin = loadable(() => import('./screens/AdminScreenComponents/PowerCoinzAdmin'), { fallback: <Loader /> })
const CategoryScreen = loadable(() => import('./screens/shoppingScreens/CategoryScreen'), { fallback: <Loader /> })
const DetailScreen = loadable(() => import('./screens/shoppingScreens/DetailScreen'), { fallback: <Loader /> })
const ShopListScreen = loadable(() => import('./screens/ShopListScreen'), { fallback: <Loader /> })
const ShopScreen = loadable(() => import('./screens/shoppingScreens/ShopScreen'), { fallback: <Loader /> })
const CartScreen = loadable(() => import('./screens/cartScreens/CartScreen'), { fallback: <Loader /> })
const CheckoutScreen = loadable(() => import('./screens/cartScreens/CheckoutScreen'), { fallback: <Loader /> })
const ThankYouScreen = loadable(() => import('./screens/cartScreens/ThankYouScreen'), { fallback: <Loader /> })
const EditProductScreen = loadable(() => import('./screens/vendorScreens/EditProductScreen'), { fallback: <Loader /> })
const EditStoreDetailsScreen = loadable(() => import('./screens/vendorScreens/EditStoreDetailsScreen'), { fallback: <Loader /> })
const LoginScreen = loadable(() => import('./screens/vendorScreens/LoginScreen'), { fallback: <Loader /> })
const OrdersScreen = loadable(() => import('./screens/vendorScreens/OrdersScreen'), { fallback: <Loader /> })
const ShippingScreen = loadable(() => import('./screens/vendorScreens/ShippingScreen'), { fallback: <Loader /> })
const TermsScreen = loadable(() => import('./screens/vendorScreens/TermsScreen'), { fallback: <Loader /> })
const YourProductsScreen = loadable(() => import('./screens/vendorScreens/YourProductsScreen'), { fallback: <Loader /> })
const AboutScreen = loadable(() => import('./screens/AboutScreen'), { fallback: <Loader /> })
const ContactScreen = loadable(() => import('./screens/ContactScreen'), { fallback: <Loader /> })
const SearchScreen = loadable(() => import('./screens/SearchScreen'), { fallback: <Loader /> })
const Help = loadable(() => import('./screens/Help'), { fallback: <Loader /> })
const Footer = loadable(() => import('./navigation/Footer'))
const AdminScreen = loadable(() => import('./screens/AdminScreen'), { fallback: <Loader /> })
const EmailAllVendors = loadable(() => import('./screens/EmailAllVendors'))
const SaleScreen = loadable(() => import('./screens/SaleScreen'), { fallback: <Loader /> })
const PromoScreen = loadable(() => import('./screens/vendorScreens/PromoScreen'), { fallback: <Loader /> })
const CastingCallsScreen = loadable(() => import('./screens/vendorScreens/CastingCallsScreen'), { fallback: <Loader /> })
const NewestScreen = loadable(() => import('./screens/NewestScreen'), { fallback: <Loader /> })
const Dashboard = loadable(() => import('./screens/vendorScreens/Dashboard'), { fallback: <Loader /> })
const CategoryProductsScreen = loadable(() => import('./screens/CategoryProductsScreen'), { fallback: <Loader /> })
const Language = loadable(() => import('./screens/Language'), { fallback: <Loader /> })
const ResetPassword = loadable(() => import('./screens/vendorScreens/LoginComponents/ResetPassword'), { fallback: <Loader /> })


const App = () => {
  const scrollToTopPaths = [
    '',
    'about',
    'language',
    'cart',
    'login',
    'about',
    'contact',
    'help',
    'powercoinz',
    'orders',
    'faqs',
    'shipping',
    'thank_you',
    'order',
    'payout',
  ]

  return (
    <div style={styles.container}>
      <HelmetProvider>
        <MetaDecorator />
        <ToastComp />
        <CacheBuster />
        <Router>
          <ScrollToTop
            paths={scrollToTopPaths}
          />
          <Routes>
            <Route path="/" element={<CategoryScreen />} />
            <Route path="/home" element={<CategoryScreen />} />
            <Route path="/shopList/:category" element={<ShopListScreen />} />
            <Route path="/shopList" element={<ShopListScreen />} />
            <Route path="/detail/:storeName/:title/" element={<DetailScreen />} />
            <Route path="/shop/:storeName/:category" element={<ShopScreen />} />
            <Route path="/shop/:storeName" element={<ShopScreen />} />
            <Route path="/cart" element={<CartScreen />} />
            <Route path="/checkout" element={<CheckoutScreen />} />
            <Route path="/thank_you/:ref" element={<ThankYouScreen />} />
            <Route path="/thank_you/" element={<ThankYouScreen />} />
            <Route path="/order/:ref" element={<ThankYouScreen />} />
            <Route path="/order/" element={<ThankYouScreen />} />
            <Route path="/powercoinz" element={<PowerCoinzScreen />} />
            <Route path="/search" element={<SearchScreen />} />
            <Route path="/about" element={<AboutScreen />} />
            <Route path="/contact" element={<ContactScreen />} />
            <Route path="/help" element={<Help />} />
            <Route path="/sale" element={<SaleScreen />} />
            <Route path="/newest" element={<NewestScreen />} />
            <Route path="/category/:category" element={<CategoryProductsScreen />} />
            <Route path="/:storeName/:title" element={<DetailScreen />} />
            <Route path="/:storeName" element={<ShopScreen />} />
            <Route path="/language" element={<Language />} />
            <Route element={<CategoryScreen />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/login" element={<LoginScreen />} />

            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/edit_product/:id" element={<EditProductScreen />} />
              <Route path="/edit_product" element={<EditProductScreen />} />
              <Route path="/your_store" element={<EditStoreDetailsScreen />} />
              <Route path="/your_admin" element={<YourAdminScreen />} />
              <Route path="/admin" element={<AdminScreen />} />
              <Route path="/admin/powercoinz" element={<PowerCoinzAdmin />} />
              <Route path="/orders" element={<OrdersScreen />} />
              <Route path="/dashboard/" element={<Dashboard />} />
              <Route path="/payout" element={<PayoutScreen />} />
              <Route path="/shipping" element={<ShippingScreen />} />
              <Route path="/faqs" element={<TermsScreen />} />
              <Route path="/terms" element={<TermsScreen />} />
              <Route path="/your_products" element={<YourProductsScreen />} />
              <Route path="/product_report" element={<Dashboard />} />
              <Route path="/emailAllVendors" element={<EmailAllVendors />} />
              <Route path="/promo" element={<PromoScreen />} />
              <Route path="/casting_calls" element={<CastingCallsScreen />} />
            </Route>
          </Routes>
          <NavbarAnimated />
          <Footer />
        </Router>
      </HelmetProvider>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    paddingTop: isMobile ? 50 : 61,
  },
}

export default App
